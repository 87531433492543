<template>
  <div class="communityResources">
    <!-- <div class="topNavigationBox">
      <div class="topNavigationInfo">
        <div class="getBack" @click="getBackFun()">
          <el-image style="width: 20px;height: 20px;" fit="cover"
            src="http://file.thinkervc.cn/c8kfhdmd6eapx2mnyach0g9vvl87t0fi82tqw7v6f3h4rufrhdmuqjtpeof8njqymzms5a7dgfpswczw.png">
          </el-image>
        </div>
        <div class="navigationTitle">社群资源</div>
      </div>
    </div>
    <div style="height: 44px;"></div> -->
    <div style="height: 20px;"></div>
    <el-carousel
      style="position: relative; background-color: #efefef; height: 180px;; overflow: hidden;border-radius: 5px;">
      <el-carousel-item style="height: 180px;" v-for="(res) in bannerList" :key="res.id">
        <el-image :src="res.bannerUrl" fit="cover" style="width: 100%;height: 180px;"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="selectList">
      <el-dropdown trigger="click" @command="handleCommand" style="max-height: 400px;">
        <span class="el-dropdown-link">
          {{paramsData.provinceText}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" style="max-height: 400px;">
          <el-dropdown-item v-for="(item) in cityOptions" :command="item.id">{{item.value}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" @command="handleCommand2">
        <span class="el-dropdown-link">
          {{paramsData.industry}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item) in positionOptions" :command="item.label">{{item.value}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" @command="handleCommand3">
        <span class="el-dropdown-link">
          {{paramsData.projectType}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item) in detailOptions" :command="item.label">{{item.value}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="sourceContainer" @click="sourceDetailsFun(item)" v-for="(item) in resourceList">
      <div class="sourceItem">
        <div class="bigTitle">{{item.title}}</div>
        <div class="subTitle">{{item.projectIntroduction}}</div>
        <div class="middle">
          <span class="tag">{{item.projectType}}</span>
          <span class="tagTitle"> {{objStrToObj(item.province)}} # {{item.industry}}</span>
        </div>
      </div>
    </div>
    <div @click="nextPage()" class="examineBut" v-if="totalShow == true">查看更多</div>
    <div v-if="resourceList.length == 0" class="reminderText">
      没有更多了
    </div>
    <div class="fixedAsideIcon">
      <el-popover placement="top-start" title="请扫码在小程序内进行操作" width="220" trigger="click">
        <div style="text-align: center;">
          <el-image fill="contain" :src="require('@/assets/img/mobile/codeImg_2.png')"
            style="width: 10.9375rem;height: 10.9375rem;"></el-image>
        </div>
        <div
          style="width: 71px;height: 71px;display: flex;flex-flow: column nowrap;justify-content: center;align-items: center;"
          slot="reference">
          <div class="img-container">
            <el-image :src="imgContainer" fit="cover" style="width: 100%;height: 100%;"></el-image>
          </div>
          <span class="title" slot="reference">发布</span>
        </div>
      </el-popover>




    </div>
  </div>
</template>

<script>
  import {
    request
  } from '@/utils/request';
  export default {
    data() {
      return {
        bannerList: [],
        cityOptions: [
          {id:"0", cityId:"0",label: "全国",value: "全国"},
          {id:"1", cityId:"110000",label: "北京市",value: "北京市"},
          {id:"2", cityId:"120000",label: "天津",value: "天津市"},
          {id:"3", cityId:"130000",label: "河北",value: "河北省"},
          {id:"4", cityId:"140000",label: "山西",value: "山西省"},
          {id:"5", cityId:"150000",label: "内蒙古",value: "内蒙古自治区"},
          {id:"6", cityId:"210000",label: "辽宁",value: "辽宁省"},
          {id:"7", cityId:"220000",label: "吉林",value: "吉林省"},
          {id:"8", cityId:"230000",label: "黑龙江",value: "黑龙江省"},
          {id:"9", cityId:"310000",label: "上海",value: "上海市"},
          {id:"10", cityId:"320000",label: "江苏",value: "江苏省"},
          {id:"11", cityId:"330000",label: "浙江",value: "浙江省"},
          {id:"12", cityId:"340000",label: "安徽",value: "安徽省"},
          {id:"13", cityId:"350000",label: "福建",value: "福建省"},
          {id:"14", cityId:"360000",label: "江西",value: "江西省"},
          {id:"15", cityId:"370000",label: "山东",value: "山东省"},
          {id:"16", cityId:"410000",label: "河南",value: "河南省"},
          {id:"17", cityId:"420000",label: "湖北",value: "湖北省"},
          {id:"18", cityId:"430000",label: "湖南",value: "湖南省"},
          {id:"19", cityId:"440000",label: "广东",value: "广东省"},
          {id:"20", cityId:"450000",label: "广西",value: "广西壮族自治区"},
          {id:"21", cityId:"460000",label: "海南",value: "海南省"},
          {id:"22", cityId:"500000",label: "重庆",value: "重庆市"},
          {id:"23", cityId:"510000",label: "四川",value: "四川省"},
          {id:"24", cityId:"520000",label: "贵州",value: "贵州省"},
          {id:"25", cityId:"530000",label: "云南",value: "云南省"},
          {id:"26", cityId:"540000",label: "西藏",value: "西藏自治区"},
          {id:"27", cityId:"610000",label: "陕西",value: "陕西省"},
          {id:"28", cityId:"620000",label: "甘肃",value: "甘肃省"},
          {id:"29", cityId:"630000",label: "青海",value: "青海省"},
          {id:"30", cityId:"640000",label: "宁夏",value: "宁夏回族自治区"},
          {id:"31", cityId:"650000",label: "新疆",value: "新疆维吾尔自治区"},
          {id:"32", cityId:"710000",label: "台湾",value: "台湾省"},
          {id:"33", cityId:"810000",label: "香港",value: "香港特别行政区"},
          {id:"34", cityId:"820000",label: "澳门",value: "澳门特别行政区"}
        ],
        positionOptions: [
          {label: '全部行业',value: '全部行业'},
          {label: '大消费',value: '大消费'},
          {label: '企业服务',value: '企业服务'},
          {label: '教育培训',value: '教育培训'},
          {label: '医疗健康',value: '医疗健康'},
          {label: '新农业',value: '新农业'},
          {label: '美业',value: '美业'},
          {label: '餐饮业',value: '餐饮业'},
          {label: '旅游出行',value: '旅游出行'},
          {label: '智能硬件',value: '智能硬件'},
          {label: '5G',value: '5G'},
          {label: '人工智能',value: '人工智能'},
          {label: '文化娱乐',value: '文化娱乐'},
          {label: '工具软件',value: '工具软件'},
          {label: '大数据',value: '大数据'},
          {label: '新能源',value: '新能源'},
          {label: '物联网',value: '物联网'},
          {label: '其他',value: '其他'},
          {label: '信息通信',value: '信息通信'},
          {label: '体育',value: '体育'},
          {label: '生活服务',value: '生活服务'},
          {label: '金融',value: '金融'},
          {label: '环保',value: '环保'},
          {label: '区块链',value: '区块链'},
          {label: '智能制造',value: '智能制造'},
          {label: '物流仓储',value: '物流仓储'},
          {label: '汽车交通',value: '汽车交通'},
          {label: '社区社交',value: '社区社交'},
          {label: '电子商务',value: '电子商务'},
          {label: 'VR/AR',value: 'VR/AR'}
        ],
        detailOptions: [
          {label: '全部类型',value: '全部类型'},
          {label: '供应链',value: '供应链'},
          {label: '渠道',value: '渠道'},
          {label: '招商加盟',value: '招商加盟'},
          {label: '企业服务',value: '企业服务'},
          {label: '政府招商',value: '政府招商'}
        ],
        imgContainer: "http://file.thinkervc.cn/476fp2mt282clu9hjufgm58wapaigfx8srny140akteytlufa47bwdq9aukf6xo21rx0t6im0bjieibu.png",
        //资源列表
        resourceList: [],
        paramsData: {
          pageNum: 1,
          pageSize: 10,
          provinceText: "全国",
          province: "全国",
          industry: "全部行业",
          projectType: "全部类型"
        },
        total: 0,
        totalShow: false,
      };
    },
    created() {
      this.bannerInfo();
      this.cityOptionsInfo();
      this.resourceInfo();
    },
    methods: {
      handleCommand(e) {
        console.log(e);
        if(e == 0){
          this.paramsData.provinceText = "全国";
          this.paramsData.province = "全国";
          this.paramsData.pageNum = 1;
          this.resourceInfo();
          return
        }
        var name = this.cityOptions[e].value;
        var id = this.cityOptions[e].cityId;
        this.paramsData.provinceText = name;
        this.paramsData.province = name + ',' + id;
        this.paramsData.pageNum = 1;
        this.resourceInfo();
      },
      handleCommand2(e) {
        this.paramsData.industry = e;
        this.paramsData.pageNum = 1;
        this.resourceInfo();
      },
      handleCommand3(e) {
        this.paramsData.projectType = e;
        this.paramsData.pageNum = 1;
        this.resourceInfo();
      },
      sourceDetailsFun(data) {
        this.$router.push({
          name: "CommunityDetail",
          query: {
            id: data.id
          }
        });
      },
      getBackFun() {
        this.$router.go(-1);
      },
      /**轮播图**/
      bannerInfo() {
        let url = 'https://xinke.thinkervc.cn/api/applet/CommunityBanner/getBannerList';
        request(url).then(res => {
          if (res.code === 200) {
            this.bannerList = res.data;
          }
        })
      },
      /**查询选择数据全国**/
      cityOptionsInfo() {
        //全国
        // var key = "V42BZ-OHRKG-KTUQN-II7YJ-MCEEF-I3BEG";
        // let url = 'https://apis.map.qq.com/ws/district/v1/getchildren?key=' + key;
        // request(url,{},{ method: 'get'}).then(res => {})
      },
      nextPage() {
        this.paramsData.pageNum = this.paramsData.pageNum + 1;
        this.resourceInfo();
      },
      resourceInfo() {
        var paramsData = this.paramsData;
        console.log(paramsData);
        let url = 'https://xinke.thinkervc.cn/api/applet/institutions/getCommunityList';
        url += '?pageNum=' + this.paramsData.pageNum + '&pageSize=' + this.paramsData.pageSize;
        if (paramsData.province == "全国") {
          url += '&province=' + "";
        } else {
          url += '&province=' + paramsData.province;
        }
        if (paramsData.industry == "全部行业") {
          url += '&industry=' + "";
        } else {
          url += '&industry=' + paramsData.industry;
        }
        if (paramsData.projectType == "全部类型") {
          url += '&projectType=' + "";
        } else {
          url += '&projectType=' + paramsData.projectType;
        }
        request(url).then(res => {
          if (res.code === 200) {
            var list = res.data.records;
            if (this.paramsData.pageNum === 1) {
              this.resourceList = list;
            } else {
              var data = this.resourceList.concat(list);
              this.resourceList = data;
            }
            if (list.length <= 10 && res.data.total < 10) {
              this.totalShow = false;
            } else {
              this.totalShow = true;
            }
            console.log(this.resourceList);
          }
        })
      },
      objStrToObj(objStr) {
        if (objStr) {
          let arr = objStr.split(',')
          return arr[0]
        }
        return ''
      }
    },
    components: {}
  };
</script>

<style scoped lang="scss">
  .communityResources {
    width: 100%;
    padding: 0 19px;
  }

  .topNavigationBox {
    position: fixed;
    width: 100%;
    height: 44px;
    background-color: #FFFFFF;
    left: 0;
    top: 0;
    z-index: 111111;
    color: #000;
    font-size: 18px;
    letter-spacing: 2px;
  }

  .topNavigationInfo {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 10px;
  }

  .getBack {
    position: absolute;
    width: 40px;
    height: 100%;
    left: 10px;
    display: flex;
    align-items: center;
    top: 0;
  }

  .navigationTitle {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .selectList {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 39px 0;
  }

  .sourceItem {
    width: 100%;
    padding: 15px;
    margin-bottom: 19px;
    box-shadow: 0 0 11px 0 rgb(94 94 94 / 16%);
  }

  .bigTitle {
    font-size: 22px;
    font-weight: bold;
  }

  .subTitle {
    color: #606266;
    font-size: 15px;
    padding: 8px 0;
  }

  .middle {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 8px 0;
  }

  .tag {
    display: inline-block;
    color: rgb(81, 80, 255);
    background-color: rgb(212, 212, 255);
    padding: 0 13px;
    height: 30px;
    line-height: 30px;
    border: 1px solid rgb(161, 161, 255);
    border-radius: 3px;
    font-size: 13px;
  }

  .tagTitle {
    display: inline-block;
    color: #909399;
    margin-left: 9px;
    line-height: 30px;
  }

  .fixedAsideIcon {
    position: fixed;
    right: 26px;
    bottom: 256px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 71px;
    height: 71px;
    opacity: 1;
    background: linear-gradient(180deg, #8f8fff, #3e3ce9);
    border-radius: 50%;
    box-shadow: 0 7px 19px 0 rgb(81 80 255 / 45%);
  }

  .img-container {
    width: 26px;
    height: 26px;
    padding: 1px;
  }

  .title {
    color: #FFFFFF;
    font-weight: bold;
  }

  .examineBut {
    width: 90%;
    height: 40px;
    border: 1px solid #ffae34;
    border-radius: 14px;
    font-size: 13px;
    background-color: #ff9834;
    color: #fff;
    line-height: 40px;
    text-align: center;
    margin-top: 20px;
  }
  .reminderText{
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    color: #6666;
  }

  ul {
    max-height: 400px !important;
    overflow: scroll !important;
  }
</style>
